<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<BundleSetForm
		v-else
		:default-data="defaultData"
		is-edit
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BundleSetForm from '@/components/BundleSetForm.vue';
import { transformedBundleSetToFormData } from '../assets/js/transform/bundleSet';

export default {
	name: 'BundleSetEdit',

	components: {
		BundleSetForm,
	},

	data() {
		return {
			isLoading: true,
			defaultData: null,
		};
	},

	computed: {
		...mapState('bundleSet', {
			edit: 'edit',
		}),
	},

	async created() {
		const id = Number(this.$route.params.id) ?? null;

		if (id) {
			try {
				this.isLoading = true;
				await this.getBundleSet(id);
				this.extractData();
			} finally {
				this.isLoading = false;
			}
		}
	},

	methods: {
		...mapActions({
			getBundleSet: 'bundleSet/getBundleSet',
		}),

		extractData() {
			if (this?.edit?.data) {
				this.defaultData = transformedBundleSetToFormData(this.edit.data);
			}
		},
	},
};
</script>
